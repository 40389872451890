// Generated by Framer (c60b0a0)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as localizedValues from "./UUvt9RIZ0-0.js";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const cycleOrder = ["gbhTy5aEX"];

const serializationHash = "framer-1UcUR"

const variantClassNames = {gbhTy5aEX: "framer-v-18q59dz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, iconColor, id, textalitur, texti, width, ...props}) => { return {...props, eNBOLr0QW: icon ?? props.eNBOLr0QW ?? "LocalFireDepartment", G5gQGi9sW: textalitur ?? props.G5gQGi9sW ?? "var(--token-1f6fdd3b-06fb-44b0-9f19-7f6a24d6a4b6, rgb(214, 39, 106))", HwREerR6D: iconColor ?? props.HwREerR6D ?? "var(--token-1f6fdd3b-06fb-44b0-9f19-7f6a24d6a4b6, rgb(214, 39, 106))", Zsdz2efeH: texti ?? props.Zsdz2efeH ?? "Vinsælast"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: string;iconColor?: string;texti?: string;textalitur?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, eNBOLr0QW, HwREerR6D, Zsdz2efeH, G5gQGi9sW, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "gbhTy5aEX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-18q59dz", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"gbhTy5aEX"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1684uwz-container"} layoutDependency={layoutDependency} layoutId={"GSo4OYY36-container"}><Material color={HwREerR6D} height={"100%"} iconSearch={getLocalizedValue("v0", activeLocale) ?? "Home"} iconSelection={eNBOLr0QW} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"GSo4OYY36"} layoutId={"GSo4OYY36"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7R2FiYXJpdG8tNzAw", "--framer-font-family": "\"Gabarito\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "100%", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-G5gQGi9sW-UUvt9RIZ0))"}}>Vinsælast</motion.p></React.Fragment>} className={"framer-bsjz2i"} fonts={["GF;Gabarito-700"]} layoutDependency={layoutDependency} layoutId={"meTtYOSiT"} style={{"--extracted-r6o4lv": "var(--variable-reference-G5gQGi9sW-UUvt9RIZ0)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-G5gQGi9sW-UUvt9RIZ0": G5gQGi9sW}} text={Zsdz2efeH} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1UcUR.framer-c4qzcv, .framer-1UcUR .framer-c4qzcv { display: block; }", ".framer-1UcUR.framer-18q59dz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 2px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-1UcUR .framer-1684uwz-container { flex: none; height: 18px; position: relative; width: 18px; }", ".framer-1UcUR .framer-bsjz2i { flex: none; height: auto; position: relative; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1UcUR.framer-18q59dz { gap: 0px; } .framer-1UcUR.framer-18q59dz > * { margin: 0px; margin-left: calc(2px / 2); margin-right: calc(2px / 2); } .framer-1UcUR.framer-18q59dz > :first-child { margin-left: 0px; } .framer-1UcUR.framer-18q59dz > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 79
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"eNBOLr0QW":"icon","HwREerR6D":"iconColor","Zsdz2efeH":"texti","G5gQGi9sW":"textalitur"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerUUvt9RIZ0: React.ComponentType<Props> = withCSS(Component, css, "framer-1UcUR") as typeof Component;
export default FramerUUvt9RIZ0;

FramerUUvt9RIZ0.displayName = "VerðPromo";

FramerUUvt9RIZ0.defaultProps = {height: 18, width: 79};

addPropertyControls(FramerUUvt9RIZ0, {eNBOLr0QW: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "LocalFireDepartment", description: undefined, hidden: undefined, title: "Icon"}, HwREerR6D: {defaultValue: "var(--token-1f6fdd3b-06fb-44b0-9f19-7f6a24d6a4b6, rgb(214, 39, 106)) /* {\"name\":\"Pink\"} */", title: "IconColor", type: ControlType.Color}, Zsdz2efeH: {defaultValue: "Vinsælast", displayTextArea: false, title: "Texti", type: ControlType.String}, G5gQGi9sW: {defaultValue: "var(--token-1f6fdd3b-06fb-44b0-9f19-7f6a24d6a4b6, rgb(214, 39, 106))", title: "Textalitur", type: ControlType.Color}} as any)

addFonts(FramerUUvt9RIZ0, [{explicitInter: true, fonts: [{family: "Gabarito", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/gabarito/v7/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj2BMW0EwItq6bFIg.woff2", weight: "700"}]}, ...MaterialFonts], {supportsExplicitInterCodegen: true})